import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    SpacingToken,
    UiSelect,
    UiStack,
    UiText,
} from '@experiences/ui-common';
import {
    getEnvVariableValue,
    useFormKeysValidation,
} from '@experiences/util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import { ApAlertBar } from '@uipath/portal-shell-react';
import { produce } from 'immer';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import {
    certificateLocationUsageOptions,
    Saml2BindingType,
    samlBindingTypeOptions,
} from '../../../common/constants/ExternalIdentityProviderConstant';
import type { IFieldAttribute } from '../../../common/interfaces/cis/attribute';
import { AttributeType } from '../../../common/interfaces/cis/attribute';
import type { ISamlFormData } from '../../../common/interfaces/cis/saml';
import AttributeMapping from '../subcomponents/AttributeMapping';
import { useSAMLFormContext } from './SAMLFormContextProvider';

const useStyles = makeStyles(() =>
    createStyles({
        fieldMini: { marginTop: '4px' },
        infoIcon: {
            width: '18px',
            height: '18px',
            marginLeft: '4px',
            marginTop: '1px',
            cursor: 'pointer',
        },
    }),
);

const SAMLAttributeMappingPageComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const HideSAMLServiceCertificateUsage = useFeatureFlagValue(Features.HideSAMLServiceCertificateUsage.name);
    const EnableSAMLAuthNToggle = useFeatureFlagValue(Features.EnableSAMLAuthNToggle.name);

    const {
        register,
        control,
        formState: { errors },
        setValue,
        watch,
    } = useFormContext<ISamlFormData>();

    const [ enableCustomIdentifier, setEnableCustomIdentifier ] = useState(() =>
        !!watch('attributes')?.find(obj => obj.attributeType === AttributeType.IDENTIFIER));

    const activeKeys = useMemo(() => {
        if (enableCustomIdentifier) {
            return [ 'attributes' ];
        }
        return [ 'attributes', 'ProvisioningSetting.AllowedDomains' ];
    }, [ enableCustomIdentifier ]);

    useFormKeysValidation(activeKeys);

    const bindingTypes = useMemo(() => {
        const options: any = { ...samlBindingTypeOptions };
        delete options[Saml2BindingType.Artifact];

        return options;
    }, []);

    const { isEdit } = useSAMLFormContext();

    const handleEnableCustomIdentifier = useCallback((checked: boolean) => {
        setEnableCustomIdentifier(checked);
        setValue('attributes', produce(watch('attributes') ?? [] as IFieldAttribute[], draftState => {
            const identifierIndex = draftState.findIndex(item => item.attributeType === AttributeType.IDENTIFIER);
            if (identifierIndex !== -1) {
                draftState.splice(identifierIndex, 1);
            }
            const displayNameAttribute = draftState.find(item => item.attributeType === AttributeType.DISPLAYNAME);
            const displayNameIndex = draftState.findIndex(item => item.attributeType === AttributeType.DISPLAYNAME);
            if (displayNameIndex !== -1) {
                draftState.splice(displayNameIndex, 1);
            }
            const emailAttribute = draftState.find(item => item.attributeType === AttributeType.EMAIL);
            if (checked && emailAttribute) {
                emailAttribute.disableRemove = false;
                emailAttribute.disableEdit = false;
            }
            const emailIndex = draftState.findIndex(item => item.attributeType === AttributeType.EMAIL);
            if (!checked && emailIndex !== -1) {
                draftState.splice(emailIndex, 1);
            }
            draftState.unshift(
                ...(checked ? [
                    {
                        attributeType: AttributeType.IDENTIFIER,
                        value: '',
                        disableRemove: true,
                    },
                ] : []),
                {
                    attributeType: AttributeType.DISPLAYNAME,
                    value: displayNameAttribute?.value ?? '',
                    disableRemove: true,
                },
                ...(!checked ? [
                    {
                        attributeType: AttributeType.EMAIL,
                        value: 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
                        disableRemove: true,
                        disableEdit: true,
                    },
                ] : [])
            );
        }));
        setValue('ProvisioningSetting.AllowedDomains', '');
    }, [ setValue, watch ]);

    return <>
        <ApAlertBar cancelable={false}>
            <UiText>
                <FormattedMessage
                    id='CLIENT_SAML_ATTRIBUTE_MAPPING_ALERT'
                    values={{
                        b: (chunks: React.ReactNode[]) => <b>
                            {chunks}
                        </b>,
                    }} />
            </UiText>
        </ApAlertBar>

        <UiStack
            mt={SpacingToken.L}
            gap={SpacingToken.L}>

            {/* Left column */}
            <UiStack
                flex={1}
                m={SpacingToken.Micro}
                direction="column">
                <UiText variant={FontVariantToken.fontSizeMBold}>
                    {translate({ id: 'CLIENT_SAML_IDP_STEP_3' })}
                </UiText>
                <FormControlLabel
                    sx={{ marginLeft: '4px' }}
                    control={
                        <Switch
                            checked={enableCustomIdentifier}
                            disabled={isEdit}
                            onChange={e => handleEnableCustomIdentifier(e.target.checked)}
                            color="primary"
                            data-cy="enable-custom-identifier-toggle"
                        />
                    }
                    label={
                        <UiText variant={FontVariantToken.fontSizeM}>
                            {translate({ id: 'CLIENT_ENABLE_CUSTOM_UNIQUE_IDENTIFIER' })}
                        </UiText>
                    }
                />
                {enableCustomIdentifier && <FormHelperText>
                    {translate({ id: 'CLIENT_UNIQUE_IDENTIFIER_DISCLAIMER' })}
                </FormHelperText>}
                <AttributeMapping
                    type="saml"
                    showUniqueIdentifier={enableCustomIdentifier} />
            </UiStack>

            {/* Right column */}
            <UiStack
                flex={1}
                m={SpacingToken.Micro}
                direction="column"
                gap={SpacingToken.M}>
                <UiStack
                    direction="column"
                    gap={SpacingToken.Micro}>
                    <UiStack>
                        <UiText variant={FontVariantToken.fontSizeMBold}>
                            {translate({ id: 'CLIENT_ALLOWED_DOMAINS' })}
                        </UiText>
                        <Tooltip
                            arrow
                            title={translate({ id: 'CLIENT_ALLOWED_DOMAINS_HELP_TEXT' })}>
                            <InfoOutlinedIcon className={classes.infoIcon} />
                        </Tooltip>
                    </UiStack>
                    <TextField
                        InputLabelProps={{ id: 'allowedDomainsLabel' }}
                        inputProps={{
                            'aria-labelledby': 'allowedDomainsLabel',
                            ...register('ProvisioningSetting.AllowedDomains', { required: !enableCustomIdentifier }),
                        }}
                        InputProps={{ disabled: enableCustomIdentifier }}
                        variant="outlined"
                        fullWidth
                        error={!!errors.ProvisioningSetting?.AllowedDomains}
                        helperText={
                            (errors.ProvisioningSetting?.AllowedDomains?.type === 'required'
                            && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' }))
                            || translate({ id: 'CLIENT_ALLOWED_DOMAINS_HELPER_TEXT' })
                        }
                        data-cy="configure-jit-allowed-domains-field"
                    />
                </UiStack>

                <UiStack direction="column">
                    <UiStack>
                        <UiText variant={FontVariantToken.fontSizeMBold}>
                            {translate({ id: 'CLIENT_SAML_BINDING_TYPE' })}
                        </UiText>
                        <Tooltip
                            arrow
                            title={translate({ id: 'CLIENT_SAML_BINDING_TYPE_TOOLTIP' })}>
                            <InfoOutlinedIcon className={classes.infoIcon} />
                        </Tooltip>
                    </UiStack>
                    <UiSelect
                        control={control}
                        className={classes.fieldMini}
                        name="Saml2BindingType"
                        error={!!errors.Saml2BindingType}
                        options={bindingTypes}
                        required
                        helperText={errors.Saml2BindingType?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                        fullWidth
                        dataCy="configure-saml2-binding-type"
                    />
                </UiStack>

                {!HideSAMLServiceCertificateUsage && (
                    <UiStack direction="column">
                        <UiStack>
                            <UiText variant={FontVariantToken.fontSizeMBold}>
                                {translate({ id: 'CLIENT_SERVICE_CERTIFICATE_USAGE' })}
                            </UiText>
                            <Tooltip
                                arrow
                                title={translate({ id: 'CLIENT_SAML_SERVICE_CERTIFICATE_USAGE_TOOLTIP' })}>
                                <InfoOutlinedIcon className={classes.infoIcon} />
                            </Tooltip>
                        </UiStack>
                        <UiSelect
                            control={control}
                            name="ServiceCertificateUsage"
                            error={!!errors.ServiceCertificateUsage}
                            options={certificateLocationUsageOptions}
                            fullWidth
                            dataCy="edit-certificate-locations-service-usage"
                            required
                            helperText={errors.ServiceCertificateUsage?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                            className={classes.fieldMini}
                        />
                    </UiStack>)}

                <Controller
                    name="AllowUnsolicitedAuthnResponse"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                    color="primary"
                                    data-cy="configure-saml-allow-unsolicited-authn-response"
                                />
                            }
                            label={
                                <UiStack>
                                    <UiText variant={FontVariantToken.fontSizeM}>
                                        {translate({ id: 'CLIENT_ALLOW_UNSOLICITED_AUTHENTICATION_RESPONSE' })}
                                    </UiText>
                                    <Tooltip
                                        arrow
                                        title={translate({ id: 'CLIENT_SAML_UNSOLICITED_AUTHENTICATION_TOOLTIP' }, { productName: getEnvVariableValue('PRODUCT_NAME') })}>
                                        <InfoOutlinedIcon className={classes.infoIcon} />
                                    </Tooltip>
                                </UiStack>
                            }
                        />
                    )}
                />

                {EnableSAMLAuthNToggle && <Controller
                    name="AdvancedSettings.IdentityProviderWantsAuthenticationRequestsSigned"
                    control={control}
                    render={({ field }) => (
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={field.value}
                                    onChange={e => field.onChange(e.target.checked)}
                                    color="primary"
                                    data-cy="configure-saml-sign-authn-request"
                                />
                            }
                            label={
                                <UiStack>
                                    <UiText variant={FontVariantToken.fontSizeM}>
                                        {translate({ id: 'CLIENT_SIGN_AUTHN_REQUEST' })}
                                    </UiText>
                                    <Tooltip
                                        arrow
                                        title={translate({ id: 'CLIENT_SIGN_AUTHN_REQUEST_TOOLTIP' })}>
                                        <InfoOutlinedIcon className={classes.infoIcon} />
                                    </Tooltip>
                                </UiStack>
                            }
                        />
                    )}
                />}
            </UiStack>
        </UiStack>
    </>;
};

export default SAMLAttributeMappingPageComponent;
